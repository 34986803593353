*{
  margin: 0px;
}

@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}

html, body{
  font-family:IBM Plex Sans, helvetica;
  background: url("assets/square.png") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse  2s infinite;
  /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse  2s infinite;
  /* Fx 5+ */
  -o-animation: bg-scrolling-reverse  2s infinite;
  /* Opera 12+ */
  animation: bg-scrolling-reverse  2s infinite;
  /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  color: #FDFFF5;
}

#my-name{
  font-size: 5vw;
  font-weight: bold;
  margin-top: 10px;
  color: #FDFFF5;
  background:
    linear-gradient(  
      to right,
      #1480fb00,
      #147efb,
      #1480fb00
  );
  background-size: 100% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
}

#hero{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  height: 20vh;
  width: calc(60vw - 10px);
  padding-top: 20px;
}

#content{
  width: 40vw;
  height: 100vh;
  position: absolute;
  right: 0px;
  top: 0px;
  scroll-behavior: smooth;
  overflow:hidden;
  box-shadow: -2px 0px #147efb, -4px 0px #0464d5, -6px 0px #034ba0, -8px 0px #02326b, -10px 0px #011935;
  background: url("assets/square.png") repeat 0 0;
  -webkit-animation: bg-scrolling 2s infinite;
  /* Safari 4+ */
  -moz-animation: bg-scrolling 2s infinite;
  /* Fx 5+ */
  -o-animation: bg-scrolling 2s infinite;
  /* Opera 12+ */
  animation: bg-scrolling 2s infinite;
  /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}


@keyframes blue-fade{
  0%{
    background-color: #011935;
  }
  25%{
    background-color: #02326b;
  }
  50%{
    background-color: #011935;
  }
  75%{
    background-color: #02326b;
  }
  100%{
    background-color: #011935;
  }
}


/* About */
#profile-pic{
  width: 12vw;
  height: 18vw;
  border-radius: 50%;
  box-shadow: 5px 2px #147efb, 10px 4px #0464d5, 15px 6px #034ba0, 20px 8px #02326b, 25px 10px #011935;
  animation: morphing 15s infinite;
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 5px 2px #147efb, 10px 4px #0464d5, 15px 6px #034ba0, 20px 8px #02326b, 25px 10px #011935;  
  }
  25% { 
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
    box-shadow: 5px 2px #147efb, 10px 4px #0464d5, 15px 6px #034ba0, 20px 8px #02326b, 25px 10px #011935;  
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: 5px 2px #147efb, 10px 4px #0464d5, 15px 6px #034ba0, 20px 8px #02326b, 25px 10px #011935;  
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;	
    box-shadow: 5px 2px #147efb, 10px 4px #0464d5, 15px 6px #034ba0, 20px 8px #02326b, 25px 10px #011935;  
  }
  100%{
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 5px 2px #147efb, 10px 4px #0464d5, 15px 6px #034ba0, 20px 8px #02326b, 25px 10px #011935;  
  }
}

#grad-highlight{
  color: red;
  font-weight: bold;
}

#currAge{
  font-size: 1em;
  color: #011935;
}

#bio{
  text-align: center;
  font-size: 1em;
  margin-top: 20px;
  background-color: #147efb;
  padding: 20px;
  border-radius: 20px 20px 0px 20px;
  width: 70%;
  box-shadow: 5px 0px #0464d5, 10px 0px #034ba0, 15px 0px #02326b, 20px 0px #011935;
}

#msg{
  text-decoration: none;
  color: gray;
  border: 2px solid gray;
  box-shadow: 5px 0px #666666, 10px 0px #4d4d4d, 15px 0px #333333 , 20px 0px #1a1a1a;
  padding: 10px 20px;
  border-radius: 20px;
  width: 70%;
  margin: 20px auto;
}
#msg:hover{
  color: #147efb;
  border: 2px solid #147efb;
  box-shadow: 5px 0px #0464d5, 10px 0px #034ba0, 15px 0px #02326b, 20px 0px #011935;
}

#profile-title{
  margin-top: 20px;
  color: #FDFFF5;
  font-size: 1.5em;
}
#profile{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}

/* Clock */
#clock-wrapper{
  position: absolute;
  bottom: 0px;
  height: calc(30vh - 10px);
  width: calc(60vw - 10px);
  box-shadow: 0px -2px #147efb, 0px -4px #0464d5, 0px -6px #034ba0, 0px -8px #02326b, 0px -10px #011935;
  font-size: 1.5em;
  background: url("assets/square.png") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse  2s infinite;
  /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse  2s infinite;
  /* Fx 5+ */
  -o-animation: bg-scrolling-reverse  2s infinite;
  /* Opera 12+ */
  animation: bg-scrolling-reverse  2s infinite;
  /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}
#clock-items{
  display: flex;
  background-color: #011935bd;
  border: 2px solid #147efb;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 18px auto;
}
#clock-time{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.time{
  color: #147efb;
  font-weight: bold;
  font-size: 2em;
}

#ampm{
  margin-left: 20px;
}


#clock-day{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

div#clock-day > p{
  margin: 0px 10px;
}

#colon{
  margin: 0px 10px;
}



/* Project */
.project-wrapper{
  height: 100vh;
  text-align: center;
  position: relative;
}

.project-img{
  width: 30vw;
  height: 15vw;
  margin: 15px 0px;
  border-radius: 5px;
  box-shadow: .25vw .1vw #147efb, .5vw .2vw #0464d5, .75vw .3vw #034ba0, 1vw .4vw #02326b, 1.25vw .5vw #011935; 
}

.project-title{
  color: #FDFFF5;
  font-weight: bold;
  font-size: 1.5em;
  padding-top: 15px;
  width: 80%;
  margin: 0px auto;
  background:
    linear-gradient(
      to right,
      #1480fb00,
      #147efb,
      #1480fb00
  );
  background-size: 100% 1px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
}

#project-description{
  width: calc(90% - 60px);
  margin: 15px auto 0px auto;
  font-size: 1em;
  background-color: #147efb;
  border-radius: 20px 20px 0px 20px;
  box-shadow: 5px 0px #0464d5, 10px 0px #034ba0, 15px 0px #02326b, 20px 0px #011935;
  padding: 20px;
}

#git-link{
  text-decoration: none;
  color: #FDFFF5;
  font-size: .8em;
  font-weight: normal;
  border-bottom: 2px solid #147efb;
}

#git-link:hover{
  color: #147efb;
}

#git-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#git-logo{
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

#tools-wrapper{
  margin: 15px 0px;
}


ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: .8em;
}
li{
  list-style-type: none;
  border: 2px solid #147efb;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 10px 5px;
  background-color: #011935;
}

li:hover{
  border: 2px dashed #147efb;
}

/* Navbar */
nav{
  height: 30vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  font-size: 1.5em;
}

#nav-links-wrapper{
  display:flex;
  flex-direction: column;
  border: 1px solid #147efb;
  border-bottom: none;
  padding: 0px 20px;
  margin-top: 20px;
  border-radius: 0%;
  animation: border-flatten-top .2s;
}
@keyframes border-flatten-top{
  0%{
    border-radius: 4% 4% 0% 0%;
  }
  25%{
    border-radius: 3% 3% 0% 0%;
  }
  50%{
    border-radius: 2% 2% 0% 0%;
  }
  75%{
    border-radius: 1% 1% 0% 0%;
  }
  100%{
    border-radius: 0% 0% 0% 0%;
  }
}

#nav-links-wrapper:hover{
  animation: border-curve-top .2s;
  border-radius: 4% 4% 0% 0%;
}
@keyframes border-curve-top{
  0%{
    border-radius: 0% 0% 0% 0%;
  }
  25%{
    border-radius: 1% 1% 0% 0%;
  }
  50%{
    border-radius: 2% 2% 0% 0%;
  }
  75%{
    border-radius: 3% 3% 0% 0%;
  }
  100%{
    border-radius: 4% 4% 0% 0%;
  }
}

#nav-wrapper{
  display:flex;
  flex-direction: column;
  align-items: center;
  width: calc(60vw - 54px); /* 54px = 20px + 20px + 2px + 2px + 10px <-- The ten subtracts the right border */
  height: calc(70vh - 44px);
  background-color: #011935bd;
  margin-left: 20px;
  margin-top: 20px;
  border: 2px solid #147efb;
}

.nav-element {
  color: inherit;
  text-decoration: none;
  color: #00b3ff;
}

.nav-element {
  background:
    linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(
      to right,
      #147efb,
      #1480fb5a
  );
  background-size: 100% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 300ms;
}

.nav-element:hover {
  background-size: 0 3px, 100% 3px;
}

.nav-element-wrapper{
  margin: 8px 0px;
}
#nav-element-logos{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #147efb;
  border-top: none;
  width: calc(100% - 1.5px);
  padding: 10px 0px;
  border-radius: 0px;
  animation: border-flatten-bottom .2s;
  background-color: rgba(20, 128, 251, 0.4);
}
@keyframes border-flatten-bottom{
  0%{
    border-radius: 0px 0px 8px 8px;
    background-color: rgba(20, 128, 251, 0.2);
  }
  25%{
    border-radius: 0px 0px 6px 6px;
    background-color: rgba(20, 128, 251, .25);
  }
  50%{
    border-radius: 0px 0px 4px 4px;
    background-color: rgba(20, 128, 251, 0.3);
  }
  75%{
    border-radius: 0px 0px 2px 2px;
    background-color: rgba(20, 128, 251, 0.35);
  }
  100%{
    border-radius: 0px 0px 0px 0px;
    background-color: rgba(20, 128, 251, 0.4);
  }
}

#nav-element-logos:hover{
  animation: border-curve-bottom .2s;
  border-radius: 0px 0px 8px 8px;
  background-color: rgba(20, 128, 251, 0.2);
}
@keyframes border-curve-bottom{
  0%{
    border-radius: 0px 0px 0px 0px;
    background-color: rgba(20, 128, 251, 0.4);
  }
  25%{
    border-radius: 0px 0px 2px 2px;
    background-color: rgba(20, 128, 251, 0.35);
  }
  50%{
    border-radius: 0px 0px 4px 4px;
    background-color: rgba(20, 128, 251, 0.30);
  }
  75%{
    border-radius: 0px 0px 6px 6px;
    background-color: rgba(20, 128, 251, 0.25);
  }
  100%{
    border-radius: 0px 0px 8px 8px;
    background-color: rgba(20, 128, 251, 0.2);
  }
}
.nav-logos{
  width: 25px;
  height: 25px;
  margin: 0px 10px;
  margin-top: 5px;
  border-radius: 30%;
  border: 2px solid #147efb;
  background-color: #147efb;
  padding: 2px;
  animation: square .2s;
  border-color: #147efb;
}

@keyframes square{
  0%{
    border-radius: 50%;
    border-color: #FDFFF5;
  }
  25%{
    border-radius: 45%;
    border-color: #FDFFF5;
  }
  50%{
    border-radius: 40%;
    border-color: #7cb8fd;
  }
  75%{
    border-radius: 35%;
    border-color: #147efb;
  }
  100%{
    border-radius: 30%;
    border-color: #147efb;
  }
}

.nav-logos:hover{
  border-color: #FDFFF5;
  border-radius: 50%;
  animation: round .2s;
}

@keyframes round{
  0%{
    border-radius: 30%;
    border-color: #147efb;
  }
  25%{
    border-radius: 35%;
    border-color: #147efb;
  }
  50%{
    border-radius: 40%;
    border-color: #7cb8fd;
  }
  75%{
    border-radius: 45%;
    border-color: #FDFFF5;
  }
  100%{
    border-radius: 50%;
    border-color: #FDFFF5;
  }
}

#nav-arrow-1{
  width: 20px;
  height: 20px;
  animation-duration: 5s;
  animation-name: arrow-hover-1;
  animation-iteration-count: infinite;
  animation-delay: -2s;
}

@keyframes arrow-hover-1{
  0%{
    transform: translateX(0px);
  }
  25%{
    transform: translateX(-10px);
  }
  50%{
    transform: translateX(0px);
  }
  75%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0px);
  }
}

#nav-arrow-2{
  width: 20px;
  height: 20px;
  animation-duration: 5s;
  animation-name: arrow-hover-2;
  animation-iteration-count: infinite;
  animation-delay: -1.5s;
}

@keyframes arrow-hover-2{
  0%{
    transform: translateX(0px);
  }
  25%{
    transform: translateX(-10px);
  }
  50%{
    transform: translateX(0px);
  }
  75%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0px);
  }
}

#nav-arrow-3{
  width: 20px;
  height: 20px;
  animation-duration: 5s;
  animation-name: arrow-hover-3;
  animation-iteration-count: infinite;
  animation-delay: -1s;
}

@keyframes arrow-hover-3{
  0%{
    transform: translateX(0px);
  }
  25%{
    transform: translateX(-10px);
  }
  50%{
    transform: translateX(0px);
  }
  75%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0px);
  }
}

#nav-arrow-4{
  width: 20px;
  height: 20px;
  animation-duration: 5s;
  animation-name: arrow-hover-4;
  animation-iteration-count: infinite;
  animation-delay: -.5s;
}

@keyframes arrow-hover-4{
  0%{
    transform: translateX(0px);
  }
  25%{
    transform: translateX(-10px);
  }
  50%{
    transform: translateX(0px);
  }
  75%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0px);
  }
}

#nav-arrow-5{
  width: 20px;
  height: 20px;
  animation-duration: 5s;
  animation-name: arrow-hover-5;
  animation-iteration-count: infinite;
  animation-delay: 0s;
}

@keyframes arrow-hover-5{
  0%{
    transform: translateX(0px);
  }
  25%{
    transform: translateX(-10px);
  }
  50%{
    transform: translateX(0px);
  }
  75%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0px);
  }
}



/* Calendar */
#calendar-month{
  color: #FDFFF5;
  background-color: #147efb;
  font-size: .7em;
  padding: 3px 5px;
  border-bottom: 2px dotted #FDFFF5;
}

#calendar-day{
  color: #147efb;
  border: 2px solid #147efb;
  font-size: 1.2em;
  padding: 3px;

}

#calendar-wrapper{
  text-align: center;
  margin-right: 20px;
  box-shadow: -2px 0px #147efb, -4px 0px #0464d5, -6px 0px #034ba0, -8px 0px #02326b, -10px 0px #011935;
  background-color: #011935;
}

/* For Mobile Phones 
 -Note: all: unset; is used to remove all the default styling of the element
*/
@media screen and (max-width: 480px){
  #my-name{
    font-size: 8vw;
    margin: 20px 0px;
  }
  #nav-wrapper{
    margin-top: 15px;
    margin-bottom: 20px;

    width: calc(100vw - 44px);
    font-size: 1em;
    padding: 0px;
    height: calc(75vh - 44px);
    -webkit-animation: bg-scrolling-reverse 2s infinite;
    /* Safari 4+ */
    -moz-animation: bg-scrolling-reverse 2s infinite;
    /* Fx 5+ */
    -o-animation: bg-scrolling-reverse 2s infinite;
    /* Opera 12+ */
    animation: bg-scrolling-reverse 2s infinite;
    /* IE 10+ */
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
     animation-timing-function: linear;
  }
  #content{
    position: relative;
    width: 100vw;
    /*box-shadow: 0px -2px #147efb, 0px -4px #0464d5, 0px -6px #034ba0, 0px -8px #02326b, 0px -10px #011935;*/
    border-top: 2px dashed #147efb;
    height: 100vh;
    border-radius: 0px;
    -webkit-animation: bg-scrolling-reverse 2s infinite;
    /* Safari 4+ */
    -moz-animation: bg-scrolling-reverse 2s infinite;
    /* Fx 5+ */
    -o-animation: bg-scrolling-reverse 2s infinite;
    /* Opera 12+ */
    animation: bg-scrolling-reverse 2s infinite;
    /* IE 10+ */
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
     animation-timing-function: linear;
  }
  #clock-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    font-size: 1em;
    height: 25vh;
    
    -webkit-animation: bg-scrolling-reverse 2s infinite;
    /* Safari 4+ */
    -moz-animation: bg-scrolling-reverse 2s infinite;
    /* Fx 5+ */
    -o-animation: bg-scrolling-reverse 2s infinite;
    /* Opera 12+ */
    animation: bg-scrolling-reverse 2s infinite;
    /* IE 10+ */
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
     animation-timing-function: linear;
  }
  #clock-items{
    margin: auto auto;
  }
  #clock-time{
    margin-top: 0px;
  }
  #profile-pic{
    width: 30vw;
    height: 45vw;
  }
 #project-description{
    font-size: .8em;
  }
  .project-img{
    width: 80vw;
    height: 40vw;
  }
  #bio{
    font-size: 1em;
  }
}




